import './scss/main.scss';
import gsap from 'gsap';
import { testimonials } from './js/testimonials';
import KeenSlider from 'keen-slider';
import ScrollTrigger from 'gsap/ScrollTrigger';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);

gsap.from('.logo', { y: -100, duration: 0.75, ease: 'bounce(4)' });

(document.querySelectorAll('.button') as unknown as HTMLElement[]).forEach((button) => {
  button.addEventListener('click', () => {
    button.classList.add('clicked');
    setTimeout(() => {
      button.classList.remove('clicked');
    }, 100);
  });
});

testimonials.forEach((testimonial) => {
  const slide = document.createElement('div');
  slide.classList.add('slide');

  const copy = document.createElement('div');
  copy.innerHTML = testimonial.excerpt ? testimonial.excerpt : testimonial.full;
  slide.appendChild(copy);

  const author = document.createElement('div');
  author.classList.add('author');
  slide.appendChild(author);

  const authorName = document.createElement('div');
  authorName.classList.add('author-name');
  authorName.innerHTML = testimonial.author.name;
  author.appendChild(authorName);

  const authorPosition = document.createElement('div');
  authorPosition.classList.add('author-position');
  authorPosition.innerHTML = testimonial.author.position;
  author.appendChild(authorPosition);

  document.querySelector('.slider').appendChild(slide);
});

const buttonPreviousSlide = document.querySelector('.button-previous-slide') as HTMLButtonElement;
const buttonNextSlide = document.querySelector('.button-next-slide') as HTMLButtonElement;
const currentSlideIndicator = document.querySelector('.current-slide');
const totalSlidesIndicator = document.querySelector('.total-slides');

let currentSliderIdx = 0;
let totalSlides = 0;

const slider = new KeenSlider('.slider', {
  selector: '.slide',
  loop: false,
  slideChanged: () => {
    onSlideChange();
  },
});

currentSliderIdx = slider.track.details.rel ?? 0;
totalSlides = slider.slides.length;
buttonPreviousSlide.addEventListener('click', goToPreviousSlide);
buttonNextSlide.addEventListener('click', goToNextSlide);
totalSlidesIndicator.innerHTML = totalSlides.toString();
updateSliderNav();

function goToNextSlide() {
  slider.next();
}

function goToPreviousSlide() {
  slider.prev();
}

function updateSliderNav() {
  buttonPreviousSlide.disabled = currentSliderIdx === 0;
  buttonNextSlide.disabled = currentSliderIdx >= totalSlides - 1;
  currentSlideIndicator.innerHTML = (currentSliderIdx + 1).toString();
}

function onSlideChange() {
  currentSliderIdx = slider.track.details.rel;
  updateSliderNav();
}
