export const testimonials = [
  {
    full: `<p>Struggly has been wonderful in my classroom! My students are loving the 'struggle' and can't wait for our struggly session during the week.</p><p>It is so wonderful to see the joy when they complete a task that they have struggled their way through and the enjoyment of collecting badges for persistence and effort rather than speeding through something.</p>`,
    author: {
      name: 'Ryan Bartok',
      position: 'Teacher at Princeton Intermediate School',
    },
  },
  {
    full: `<p>Struggly was a great challenge for my students when they were done with in-class work. They were motivated to get their work done so they could work on the challenges. My students always asked to work on it when they were done with their in-class work.</p>`,
    author: {
      name: 'Erin Franson',
      position: 'Teacher at Princeton Intermediate School',
    },
  },
  {
    full: '<p>The app has a low floor and high ceiling. All of my students were able to experience the struggle and find joy in eventual solving. I would love to use it with my kids again next year.</p>',
    author: {
      name: 'Angela Harvala',
      position: 'Teacher at Princeton Intermediate School',
    },
  },
  {
    full: '<p>Struggly has been a challenging, fun way for my students to try math in a game like setting! It was a great way to challenge students and see what they could learn! It also showed them that mistakes are ok! My students absolutely loved Struggly and I hope that we can use it again next year!</p>',
    author: {
      name: 'Chad Ruzek',
      position: 'Teacher at Princeton Intermediate School',
    },
  },
  {
    full: `<p>My students have really enjoyed using Struggly. Each week I notice more and more students choosing Struggly as their technology game of choice in the classroom when given the opportunity to choose a game site.</p>`,
    author: {
      name: 'Laura Skluzacek',
      position: 'Teacher at Princeton Intermediate School',
    },
  },
  {
    full: `<p>My students have thoroughly enjoyed Struggly, it has been their app of choice ever since we began using it. They believe it is fun, and they really are drawn to it.  From a teacher's perspective, I think it has helped them to be less frustrated and more motivated to solve difficult problems. Instead of giving up, they continue to work.</p>`,
    author: {
      name: 'Melissa Ramage	',
      position: 'Teacher at Princeton Intermediate School',
    },
  },
  {
    full: `<p>It is a fun site to go to at school when I am finished with my assignment work that keeps me challenged.</p>
     <p>I like Struggly because it actually makes me think yet it is super fun at the same time.</p>
     <p>Struggly is a game perfect for your brain because it challenges you to think.</p>
     <p>Struggly is a good application to help with creating a growth mindset. Before Struggly, my mindset was more fixed about puzzles and challenges and now I am excited to challenge myself.</p>`,
    author: {
      name: 'Students',
      position: '5th grade at Princeton Intermediate School',
    },
  },
  {
    full: `<p>It isn't easy, challenges my brain in a different way.</p>
    <p>Gives immediate feedback so I know when I am wrong.</p>
    <p>I love to play Struggly. I like all the challenges and like my brain learning more. I like unlocking new tasks and stuff. I also like to get the answers right and also get them wrong cause my brain gets bigger.</p>`,
    author: {
      name: 'Students',
      position: '5th grade at Princeton Intermediate School',
    },
  },
  {
    full: `<p>It makes me think harder because it makes me struggle.</p>
    <p>I like that I can struggle with a level and take my time to work through it.</p>
    <p>Sometimes I get mad at Struggly, but it is called Struggly for a reason! And that reason is to never give up! I enjoy Mighty Mosaic, NIM, and the Tangrams. I find some of the other ones hard – they look simple but can be hard at times. I enjoy Struggly a lot!!</p>`,
    author: {
      name: 'Students',
      position: '5th grade at Princeton Intermediate School',
    },
  },
  {
    full: `<p>The thing I enjoy about Struggly is it always makes you find a way and try new things. What I find challenging is that it's hard – I don't really know how to word it – but it's hard.</p>
    <p>Struggly is a struggle sometimes, so that's why its name is Struggly. I like Struggly because it is hard and it stretches my brain. it is awesome. Struggly is an awesome program.</p>`,
    author: {
      name: 'Students',
      position: '5th grade at Princeton Intermediate School',
    },
  },
  {
    full: `<p>What I like about Struggly is that it's a puzzle. It also makes learning more fun because it is kind of like a game. Another reason is there are so many different things you can do so if you get bored with one task, then you can do another thing. Sometimes you want to finish a task so badly! But it is super hard, so it takes longer to finish it. I like Struggly.</p>`,
    author: {
      name: 'Student',
      position: '5th grade at Princeton Intermediate School',
    },
  },
  {
    full: `<p>I like that it makes your brain think hard. What is challenging for me is trying to solve the problems on my own without too much help or helping others, but I think that is good – so everyone can learn new things in their own way. I like that it is not a timing game. it is a nice thinking game that can help you learn new things and get new stuff which is fun because the more you do, the more new thinking games you get.</p>`,
    author: {
      name: 'Student',
      position: '5th grade at Princeton Intermediate School',
    },
  },
  {
    full: `<p>I feel that Struggly is a great experience for me. It has helped me with math and problem-solving, but also to think about and check my answer instead of submitting it right away. I love the challenge and how every level gets harder. I struggle when every level gets harder but I love that struggle! It teaches me that I need to keep going and not give up!</p>`,
    author: {
      name: 'Student',
      position: '5th grade at Princeton Intermediate School',
    },
  },
  {
    full: `<p>I like Struggly because it helps me learn. It is fun and challenging and I like to be challenged.</p>
    <p> I like that Struggly has different levels with new things added to the level. It is fun to figure out what needs to be done.</p>
    <p>I love Struggly because it teaches you a growth mindset. You learn all types of things like fractions, decimals, money, and patterns. It has a lot of different challenges that really make me think.</p>`,
    author: {
      name: 'Students',
      position: '3th and 4th grade at Harris Elementary School',
    },
  },
  {
    full: `<p>Struggly is very fun to play. It can be hard at first, but it helps my brain grow and is helping me do better in math. It feels good to complete a level I have been working on for a long time.</p>
    <p>I really like Struggly because it helps my brain grow. It can be hard, but I learn something every day. My favorite task is Decimals in the Well because it is about money and money is good.</p>`,
    author: {
      name: 'Students',
      position: '5th grade at Harris Elementary School',
    },
  },
  {
    full: `<p>As a homeschooling mom, I have a lot of experience weeding through different curriculums and learning programs for our kids. There are so many options out there. But this one was a really amazing find! It’s engaging, challenging, but best of all it is really fun! Seeing the accomplishment on our kids faces when they solve something they were stuck on is the best! I highly recommend it!</p>`,
    author: {
      name: `Christina`,
      position: `Homeschooling mom of a 9 year old girl`,
    },
  },
  {
    excerpt: `<p>My students are enjoying the struggle. They are learning so much about themselves as they get frustrated figuring out the puzzles but then feel proud when they make the connections and succeed. Struggly is so good for my students!!!</p>
    <p>My student Kolton says: "Struggly makes my brain hurt sometimes, but then I feel so awesome when I figure out the problem...</p>`,
    full: `<p>My students are enjoying the struggle. They are learning so much about themselves as they get frustrated figuring out the puzzles but then feel proud when they make the connections and succeed. Struggly is so good for my students!!!</p>
    <p>My student Kolton says: "Struggly makes my brain hurt sometimes, but then I feel so awesome when I figure out the problem."</p>
    <p>My student Olivia: "The puzzles helps me feel like I'm struggling. I have fun playing the fun games and I am determined to get the achievements."</p>
    <p>Ethan: "I love it! It makes me struggle and my brain grow."</p>`,
    author: {
      name: `Mrs. Bagger`,
      position: `4th Grade Teacher, CA`,
    },
  },
  {
    excerpt: `<p>My six-year-old son has been using Struggly for a few months now, and he loves it! Recently, I have noticed that he is able to do complex addition problems that are well beyond what I have taught him at home or what he has learned in school. I realized that he must have learned it from Struggly. I am amazed at how he is able to logically solve problems using his own approach.</p>`,
    full: `<p>My six-year-old son has been using Struggly for a few months now, and he loves it! He has always enjoyed math and does not have any learning difficulties, but he finds Struggly to be a fun game that he can play whenever he gets the chance. He particularly enjoys the simple problems because he can finish the levels quickly, and he loves the more difficult problems because he feels proud when he solves them.</p>
    <p>Recently, I have noticed that he is able to do complex addition problems that are well beyond what I have taught him at home or what he has learned in school. At first, I was puzzled as to how he was able to do this, but then I realized that he must have learned it from Struggly. I am amazed at how he is able to logically solve problems using his own approach.</p>
    <p>In a recent parent-teacher meeting, his teacher informed us that they had administered a Cito test, and he is already performing at the level of Class 2 in math, even though he is currently in Class 1. This is very impressive, and I am certain that Struggly has played a role in his success.</p>
    <p>One of the other things that has impressed me about Struggly is that my son has taken up the challenge to finish the game "Tower of Hanoi" in the recommended number of steps. This shows that Struggly is not just for kids who struggle with math, but it can also be a valuable resource for kids who love math and want to challenge themselves.</p>
    <p>Overall, I highly recommend Struggly to any parent looking for a fun and effective way to help their child with math.</p>`,
    author: {
      name: `Priti`,
      position: `Mother of 6 year old boy, Netherlands`,
    },
  },
  {
    full: `<p>What an amazing platform you have created! All the teachers and students I have seen engaging with Struggly have been in love with it. I’ve been delighted to see how well it reinforces growth mindset messages and teaches math in a rigorous, creative, visual and playful way!</p>`,
    author: {
      name: `Anna Scannell`,
      position: `Elementary Math Specialist, Luxembourg`,
    },
  },
  {
    full: `<p>I have been using the family subscription for the last four months to support five students with the greatest deficits. Their parents have received Growth Mindset information and learning keys from the Struggly web app. I have noticed that one of these students (8th grade) has shown steady improvement in her math scores over the time period. I just received a contact from her mother who said, "Yes, she is doing Struggly, it is helping her". This is a strong testimony to me!</p>`,
    author: {
      name: `Nick Staples`,
      position: `Teacher, Hawaii`,
    },
  },
  {
    full: `<p>We both love it! She thinks it’s fun and the parent guide is so helpful! I’ve done a few math and learning apps with her but this one is my favorite. I love how the parent guide goes in depth about what she’s doing and why it works.</p>`,
    author: {
      name: `Amanda Keith`,
      position: `Mother (child in public school), 7 year old daughter`,
    },
  },
  {
    excerpt: `<p>When I told my students last week that Struggly helps them understand math better, they didn’t believe me. They think it’s just fun.</p>
    <p>They laugh hysterically when they discover they are encouraged to struggle and mistakes are celebrated. Finding the “hack” or Easter egg has been a huge deal for the last week or two…</p>
    <p>Thank you for all you work on this app!!! It’s turning out great!</p>`,
    full: `<p>The 4th graders are sticking with the games a lot longer than the younger students but both groups were engaged immediately. When I told them last week that Struggly helps them understand math better, they didn’t believe me. They think it’s just fun.</p>
    <p>They laugh hysterically when they discover they are encouraged to struggle and mistakes are celebrated. Finding the “hack” or Easter egg has been a huge deal for the last week or two…</p>
    <p>Thank you for all you work on this app!!! It’s turning out great!</p>
    <p>From her 4th graders:</p>
    <p>S: I like Tessellation Tiles because it's fun. I also like Leap Frog.</p>
    <p>P: I love that when you make mistakes it says "Incredible, you made a mistake!"  So, it's basically encouraging.</p>
    <p>P: I mean, it's fun.  I like Decimals in a Well because it's about money.  I also like Tessellation Tiles because you can make designs.</p>`,
    author: {
      name: `Kristen Robertson`,
      position: `Math TOSA (1-4th grade)`,
    },
  },
  {
    full: `<p>I bought my nephew a Struggly subscription for his 9th birthday. I didn't have to explain anything. My nephew - even though he doesn't know any English yet - started right away and didn't want to stop.</p>`,
    author: {
      name: `Regina Jorde`,
      position: `Germany`,
    },
  },
  {
    full: `<p>I was surprised at how well Struggly works. My daughter (8) is rather unmotivated to spend a long time on math-related tasks and exercises. Struggly got her excited right from the start. And the best part is that she persevered and once even sat down to a task that my husband and I couldn't solve ourselves, only to proudly present us with the solution 20 minutes later. I would never have thought that was possible before.</p>`,
    author: {
      name: `Susanne Junglas`,
      position: `Mother of 8 year girl, Germany`,
    },
  },
  {
    excerpt: `<p>I would enthusiastically recommend Struggly to anyone with children (or anyone at all!) My children beg to play Struggly each night (as opposed to their console video games), and then beg for "extra time" when their allotted time is up - which is easy to grant since it's a learning tool.</p>`,
    full: `<p>I would enthusiastically recommend Struggly to anyone with children (or anyone at all!) My children beg to play Struggly each night (as opposed to their console video games), and then beg for "extra time" when their allotted time is up - which is easy to grant since it's a learning tool. When they ask me for help in solving the problems for them, I am so grateful that the name of the app is "Struggly" as I remind them the goal is to work through the problem on their own. It takes the pressure off of me having to make it easier for them and I am definitely watching them learn resilience and build confidence as a result.</p>
    <p>My favorite thing is watching my two children help each other with tasks they each have already completed - they are cooperative and collaborative and fill this mom-heart with joy! And, I will admit to doing a few tasks as an adult too - which is a fun way to spend a few extra minutes and challenges my brain in a way that I have missed. Very impressed with the app and can't wait to see what's next.</p>`,
    author: {
      name: `Emily A`,
      position: `Mother of two children`,
    },
  },
  {
    excerpt: `<p>My own son just tested "high average" on his latest MAP assessment in math.  That is just crazy to us considering a year ago at this time he was "far below average". His growth rate from this Fall to this Winter was in the 99th percentile. I attribute most of this to his change in attitude about math. Thank you, Struggly!</p>`,
    full: `<p>My nine-year-old son dislikes math and resists doing anything with numbers. But with Struggly, he loves doing tasks with numbers. For the first time ever, he’s excited to do math and I see him challenging himself to play with numbers and find new patterns. Thank you, Struggly, for unlocking the “fun” in math!</p>
    <p>I wish you all could be in the classroom and hear the difference Struggly is making. The word "struggle" is such a positive word in our classroom. Kids use it throughout the day. They joke that they "need to go struggle" when they are about to do a task that they are uncertain about. It's the best!</p>
    <p>And, my own son just tested "high average" on his latest MAP assessment in math. That is just crazy to us considering a year ago at this time he was "far below average". His growth rate from this Fall to this Winter was in the 99th percentile. I attribute most of this to his change in attitude about math. Thank you, Struggly!</p>`,
    author: {
      name: `Dani Quinto`,
      position: `4th Grade Teacher, Interim Preschool-8th Grade Curriculum Coordinator`,
    },
  },
  {
    excerpt: `<p>My son felt safe exploring mathematical concepts AND making mistakes as he went. I observed that this allowed him to have an open mind about new concepts that he would normally have wanted to quit at the first sign of difficulty or misunderstanding.</p>`,
    full: `<p>My daughter, Evelyn, aged 8, and son, JJ, aged 5, have had such fun using Struggly! They’ve used other online math platforms, but I’ve never seen the enthusiasm that Struggly brought out in them.</p>
    <p>My son felt safe exploring mathematical concepts AND making mistakes as he went. I observed that this allowed him to have an open mind about new concepts that he would normally have wanted to quit at the first sign of difficulty or misunderstanding.</p>
    <p>My daughter loves challenges, but also loves being right and getting to the right answer quickly. This eliminated that for her and encouraged exploration. She was taking more risks with learning as her only motivation.</p>
    <p>As a teacher and a parent, I enjoy seeing this approach to mathematics through my children’s eyes. I appreciate Struggly being a guide to encourage this growth mindset for all of us.</p>
    `,
    author: {
      name: `Tricia Pelleriti`,
      position: `Third grade teacher, San Anselmo, CA., 2 children, 8 and 5 years`,
    },
  },
  {
    excerpt: `<p>As a teacher, I have spent hours trying out math applications to recommend to families, and finally feel I can wholeheartedly recommend this one that was thoughtfully created by Dr. Boaler and the whole Struggly team.</p>`,
    full: `<p>My daughters, ages 8 and 11, were unsure at first when they explored the exercises on the Struggly app, wondering "how to play" or "how to win." The brilliance of their experience was that without them realizing it, they expanded into using flexible thinking and engaged with the process, as opposed to the result. In addition, I love the areas of mathematical thinking that have been chosen for this app.</p>
    <p>As parents, we are strict about screen time, but their time on Struggly feels valuable and in line with the mindset that I hope they are developing as young mathematicians. As a teacher, I have spent hours trying out math applications to recommend to families, and finally feel I can wholeheartedly recommend this one that was thoughtfully created by Dr. Boaler and the whole Struggly team.</p>`,
    author: {
      name: `Amy Carman`,
      position: `California Elementary School Teacher, 2 children, 8 and 11`,
    },
  },
  {
    excerpt: `<p>I am really excited by what I am already seeing in my students as they begin to use Struggly. It is so different from what they are used to doing when they are "assigned math"...I love how there are no directions and they have to figure out what to do, and I watch their faces light up when they begin to explore, get stuck, and struggle. I found myself telling a student that got stuck and then thought about a strategy for "Leap Frogs" for a while, then attacked the task, "You are learning to struggle better." They really were beaming at this prospect. I have heard back from families that they are loving it.</p>`,
    full: `<p>I am really excited by what I am already seeing in my students as they begin to use Struggly. It is so different from what they are used to doing when they are "assigned math"...I love how there are no directions and they have to figure out what to do, and I watch their faces light up when they begin to explore, get stuck, and struggle. I found myself telling a student that got stuck and then thought about a strategy for "Leap Frogs" for a while, then attacked the task, "You are learning to struggle better."</p>
    <p>They really were beaming at this prospect. I have heard back from families that they are loving it. As a Math Specialist, I can see using Struggly as part of a centers-based Intervention Model, or even something to break out when my students are burned out..!  Yep, I would say that the mathematics is beautiful in your product! Thank you!</p>`,
    author: {
      name: `Ethan Ducker`,
      position: `K-5 Math Specialist in California`,
    },
  },
];
